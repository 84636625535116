.session-cookie-overview-cookies-session-cookie-overview-cookies {
  gap: 10px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-overview-cookies-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.session-cookie-overview-cookies-container01 {
  gap: 20px;
  display: flex;
  align-items: end;
}
.session-cookie-overview-cookies-text {
  font-size: 18px;
}
.session-cookie-overview-cookies-navlink {
  display: contents;
}
.session-cookie-overview-cookies-container02 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.session-cookie-overview-cookies-icon {
  width: 18px;
  height: 18px;
}
.session-cookie-overview-cookies-text1 {
  text-transform: none;
  text-decoration: none;
}
.session-cookie-overview-cookies-icon2 {
  width: 18px;
  height: 18px;
}
.session-cookie-overview-cookies-container04 {
  flex: 1;
  width: 100%;
  height: 526px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.session-cookie-overview-cookies-container05 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.session-cookie-overview-cookies-container06 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.session-cookie-overview-cookies-text3 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.session-cookie-overview-cookies-container07 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.session-cookie-overview-cookies-text4 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.session-cookie-overview-cookies-container08 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.session-cookie-overview-cookies-text5 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.session-cookie-overview-cookies-container09 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-overview-cookies-ul {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  background-color: var(--dl-color-traffls-white);
}
