.monitor-overview-item-monitor-overview-item {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: var(--dl-color-traffls-color-000);
}
.monitor-overview-item-container {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-overview-item-text {
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-container01 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.monitor-overview-item-text01 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-text02 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-container02 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-overview-item-container03 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.monitor-overview-item-text03 {
  text-transform: none;
}
.monitor-overview-item-text04 {
  text-transform: none;
}
.monitor-overview-item-container04 {
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.monitor-overview-item-text05 {
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
}
.monitor-overview-item-text06 {
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
}
.monitor-overview-item-container05 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-overview-item-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.monitor-overview-item-text07 {
  text-transform: none;
}
.monitor-overview-item-text08 {
  text-transform: none;
}
.monitor-overview-item-container07 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.monitor-overview-item-text09 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-text10 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-status {
  gap: 10PX;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.monitor-overview-item-container08 {
  gap: 10px;
  color: #58b37f;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: #58b37f;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  animation-name: none;
  flex-direction: row;
  padding-bottom: 5px;
  animation-delay: 0s;
  justify-content: flex-end;
  background-color: rgba(88, 179, 127, 0.3);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.monitor-overview-item-container09 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: #58b37f;
}
.monitor-overview-item-text11 {
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-container10 {
  gap: 10px;
  color: var(--dl-color-traffls-color-5001);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-5001);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  animation-name: none;
  flex-direction: row;
  padding-bottom: 5px;
  animation-delay: 0s;
  justify-content: flex-end;
  background-color: rgba(238, 114, 34, 0.3);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.monitor-overview-item-container11 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-color-5001);
}
.monitor-overview-item-text12 {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
}
.monitor-overview-item-container12 {
  gap: 10px;
  color: var(--dl-color-theme-neutral-dark);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  animation-name: none;
  flex-direction: row;
  padding-bottom: 5px;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.monitor-overview-item-container13 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-color-400);
}
.monitor-overview-item-text13 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-container14 {
  gap: 10px;
  color: var(--dl-color-traffls-red);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: rgba(178, 68, 68, 0.3);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  animation-name: none;
  flex-direction: row;
  padding-bottom: 5px;
  animation-delay: 0s;
  justify-content: flex-end;
  background-color: rgba(178, 68, 68, 0.2);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.monitor-overview-item-container15 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-red);
}
.monitor-overview-item-text14 {
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-overview-item-navlink {
  display: contents;
}
.monitor-overview-item-container16 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-traffls-color-100);
}
.monitor-overview-item-icon {
  width: 18px;
  height: 18px;
}
