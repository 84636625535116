.list-overview-list-list-overview-list {
  gap: 20px;
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  overflow: auto;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.list-overview-list-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.list-overview-list-navlink {
  display: contents;
}
.list-overview-list-container1 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.list-overview-list-icon {
  width: 18px;
  height: 18px;
}
.list-overview-list-text {
  text-transform: none;
}
.list-overview-list-container2 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
}
.list-overview-list-container3 {
  display: flex;
  align-items: flex-end;
}
.list-overview-list-text1 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
}
.list-overview-list-container4 {
  display: flex;
  align-items: flex-end;
}
.list-overview-list-text3 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
}
.list {
  width: 100%;
}
.list.empty {
  border: 2px dashed #DFDFDF;
  height: 100%;

}
