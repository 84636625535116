.events-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.events-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.events-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.events-event-overview-list {
  gap: 20px;
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  overflow: auto;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.events-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.events-navlink {
  display: contents;
}
.events-container2 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.events-icon {
  width: 18px;
  height: 18px;
}
.events-text {
  text-transform: none;
}
.events-container3 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
}
.events-container4 {
  display: flex;
  align-items: flex-end;
}
.events-text1 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
}
.events-container5 {
  display: flex;
  align-items: flex-end;
}
.events-text3 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
}
