.log-overview-list-item1-log-overview-list-item {
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: stretch;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}
.log-overview-list-item1-container {
  gap: 20px;
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.log-overview-list-item1-container1 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.log-overview-list-item1-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.log-overview-list-item1-text1 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
}
.log-overview-list-item1-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.log-overview-list-item1-container3 {
  color: var(--dl-color-traffls-red);
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 5px;
  background-color: rgba(178, 68, 68, 0.1);
}
.log-overview-list-item1-text2 {
  font-size: 12px;
}
.log-overview-list-item1-container4 {
  color: var(--dl-color-traffls-color-300);
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  border-color: rgba(88, 179, 127, 0.4);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 5px;
  background-color: rgba(88, 179, 127, 0.1);
}
.log-overview-list-item1-text3 {
  font-size: 12px;
}
.log-overview-list-item1-container5 {
  gap: 10px;
  flex: 1;
  width: 743px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  flex-direction: column;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.log-overview-list-item1-text4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.log-overview-list-item1-text5 {
  opacity: 0.7;
  line-height: 1.5;
}
