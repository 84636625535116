.monitor-details-trigger-item-monitor-details-trigger-item {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-details-trigger-item-container {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
}
.monitor-details-trigger-item-container01 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.monitor-details-trigger-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.monitor-details-trigger-item-container02 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-details-trigger-item-text1 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-details-trigger-item-container03 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.monitor-details-trigger-item-container04 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.monitor-details-trigger-item-container05 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.monitor-details-trigger-item-text3 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-details-trigger-item-text4 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-details-trigger-item-text5 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-details-trigger-item-container06 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.monitor-details-trigger-item-container07 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.monitor-details-trigger-item-text7 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-details-trigger-item-container08 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.monitor-details-trigger-item-container09 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
