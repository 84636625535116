.list-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.list-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.list-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
