.login-component-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.login-component-container01 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-container02 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.login-component-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.login-component-text01 {
  opacity: 0.6;
  font-size: 12px;
}
.login-component-container03 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-d1 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-email {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text02 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container04 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-password {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-container05 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.login-component-text03 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-text04 {
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-main-1);
  cursor: pointer;
  font-size: 12px;
}
.login-component-container06 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput1 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-container07 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
}
.login-component-text05 {
  font-size: 12px;
}
.login-component-d2 {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-navlink {
  display: contents;
}
.login-component-container08 {
  text-decoration: none;
}
.login-component-container09 {
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.login-component-container10 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-component-text08 {
  font-size: 12px;
}
.login-component-text09 {
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-main-1);
  cursor: pointer;
  font-size: 12px;
}
.login-component-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
.login-component-text10 {
  fill: var(--dl-color-traffls-red);
  color: var(--dl-color-traffls-red);
  font-style: normal;
  font-weight: 700;
}
.login-component-container12 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-container13 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.login-component-text11 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.login-component-text12 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.login-component-container14 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-d11 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-email1 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text13 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container15 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput2 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-password1 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text14 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container16 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput3 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-email2 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text15 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container17 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput4 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-email3 {
  gap: 5px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text16 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container18 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput5 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-email4 {
  gap: 5px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text17 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container19 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput6 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-d21 {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-container21 {
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.login-component-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.login-component-container23 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-container24 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.login-component-text21 {
  font-size: 18px;
}
.login-component-text22 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.login-component-container25 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-d12 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-email5 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-text23 {
  opacity: 0.7;
  font-size: 12px;
}
.login-component-container26 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.login-component-textinput7 {
  width: 100%;
  height: 100%;
  border-color: #bcb6b6;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
}
.login-component-d22 {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-container28 {
  color: var(--dl-color-traffls-white);
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.login-component-container29 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-component-text26 {
  font-size: 12px;
}
.login-component-text27 {
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-main-1);
  cursor: pointer;
  font-size: 12px;
}
.login-component-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.login-component-container31 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-container32 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.login-component-text29 {
  font-size: 18px;
}
.login-component-text30 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.login-component-container33 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-d23 {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.login-component-container36 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-container37 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.login-component-text33 {
  font-size: 18px;
}
.login-component-text34 {
  opacity: 0.6;
  font-size: 12px;
  font-weight: 300;
}
.login-component-text36 {
  color: var(--dl-color-traffls-color-300);
  font-weight: 500;
}
.login-component-container38 {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-component-d24 {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.login-component-navlink1 {
  display: contents;
}
.login-component-container39 {
  text-decoration: none;
}
.login-component-container40 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.login-component-root-class-name {
  width: 100%;
}
