.top-bar-top-bar {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-color-000);
}
.top-bar-container {
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.top-bar-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.top-bar-container01 {
  flex: 0 0 auto;
  width: 10px;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-500);
}
.top-bar-text01 {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
}
.top-bar-container02 {
  gap: 20px;
  display: flex;
  position: relative;
  align-items: center;
}
.top-bar-navlink {
  display: contents;
}
.top-bar-container03 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.top-bar-text02 {
  text-transform: none;
}
.top-bar-container04 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.top-bar-container05 {
  gap: 5px;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.top-bar-text03 {
  fill: var(--dl-color-traffls-color-100);
  color: var(--dl-color-traffls-color-100);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1px;
}
.top-bar-container06 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.top-bar-text04 {
  opacity: 0.7;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
}
.top-bar-text05 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 1px;
}
.top-bar-container07 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.top-bar-container08 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.top-bar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-bar-container09 {
  gap: 2px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.top-bar-text06 {
  font-size: 14px;
}
.top-bar-text07 {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.top-bar-container10 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 200px;
  display: flex;
  z-index: 90;
  position: absolute;
  align-items: flex-start;
  padding-top: 55px;
  flex-direction: column;
}
.top-bar-container11 {
  width: 200px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.top-bar-container12 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-end;
}
.top-bar-text08 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.top-bar-text09 {
  font-size: 12px;
}
.top-bar-container13 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.top-bar-container13:hover {
  opacity: 1;
}
.top-bar-container14 {
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.top-bar-text10 {
  font-size: 12px;
}
.top-bar-container15 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-end;
}
.top-bar-container15:hover {
  opacity: 1;
}
