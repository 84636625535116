.monitor-create-form-monitor-create {
  gap: 50px;
  display: flex;
  padding: 30px;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.monitor-create-form-container {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container01 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text01 {
  width: 100%;
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container02 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.monitor-create-form-monitor-create-monitor-monitor-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.monitor-create-form-container03 {
  gap: 50px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 300px;
  min-height: 200px;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container04 {
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.monitor-create-form-container05 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text02 {
  font-size: 15px;
}
.monitor-create-form-text03 {
  width: 100%;
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.monitor-create-form-text04 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-create-form-container07 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.monitor-create-form-container08 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container09 {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-create-form-container10 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.monitor-create-form-text05 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container11 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.monitor-create-form-text06 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container12 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-li {
  padding-bottom: 0px;
}
.monitor-create-form-li01 {
  padding-bottom: 0px;
}
.monitor-create-form-li02 {
  padding-bottom: 0px;
}
.monitor-create-form-li03 {
  padding-bottom: 0px;
}
.monitor-create-form-li04 {
  padding-bottom: 0px;
}
.monitor-create-form-li05 {
  padding-bottom: 0px;
}
.monitor-create-form-li06 {
  padding-bottom: 0px;
}
.monitor-create-form-li07 {
  padding-bottom: 0px;
}
.monitor-create-form-container13 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container14 {
  gap: 10px;
  width: 348px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text07 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 22px;
  font-weight: 500;
}
.monitor-create-form-text08 {
  font-style: normal;
}
.monitor-create-form-text11 {
  font-weight: 300;
}
.monitor-create-form-text12 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-create-form-container15 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text13 {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text14 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container16 {
  gap: 30px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-container17 {
  gap: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}
.monitor-create-form-text15 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text16 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text17 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text18 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text19 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text20 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text21 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text22 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text23 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text24 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text25 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text26 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text27 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text28 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container32 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container33 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text31 {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text32 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container34 {
  gap: 30px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-container35 {
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}
.monitor-create-form-text33 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text34 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text35 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text36 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text37 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text38 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text39 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text40 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text41 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text42 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text43 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text44 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text45 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text46 {
  opacity: 0.7;
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container50 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container51 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text47 {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text48 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container52 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.monitor-create-form-container53 {
  gap: 20px;
  flex: 0 0 auto;
  width: 306px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container54 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text49 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text50 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container55 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-container56 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.monitor-create-form-text51 {
  font-size: 22px;
}
.monitor-create-form-monitor-create-monitor-budget-limit {
  width: 100%;
  height: auto;
  font-size: 22px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  background-color: Transparent;
}
.monitor-create-form-text52 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container57 {
  top: 0px;
  fill: var(--dl-color-traffls-main-1);
  flex: 0 0 auto;
  right: 20px;
  width: 60px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.monitor-create-form-icon {
  width: 30px;
  height: 30px;
}
.monitor-create-form-container58 {
  gap: 50px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 300px;
  min-height: 200px;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container59 {
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.monitor-create-form-container60 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text53 {
  font-size: 15px;
}
.monitor-create-form-text54 {
  width: 100%;
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container61 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.monitor-create-form-text55 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-create-form-container62 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.monitor-create-form-container63 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container64 {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-create-form-container65 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.monitor-create-form-text56 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container66 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.monitor-create-form-text57 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container67 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-li08 {
  padding-bottom: 0px;
}
.monitor-create-form-container68 {
  gap: 50px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 300px;
  min-height: 200px;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container69 {
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.monitor-create-form-container70 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text58 {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text59 {
  width: 100%;
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container71 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.monitor-create-form-text60 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.monitor-create-form-container72 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.monitor-create-form-container73 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container74 {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.monitor-create-form-container75 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.monitor-create-form-text61 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container76 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.monitor-create-form-text62 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.monitor-create-form-container77 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-li09 {
  padding-bottom: 0px;
}
.monitor-create-form-container78 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.monitor-create-form-container79 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.monitor-create-form-text63 {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.monitor-create-form-text64 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container80 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-container81 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.monitor-create-form-container82 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container83 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text65 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text66 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container84 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container85 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text67 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text68 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container86 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.monitor-create-form-container87 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container88 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text69 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text70 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container89 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container90 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text71 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text72 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container91 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.monitor-create-form-container92 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container93 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text73 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text74 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container94 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.monitor-create-form-container95 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.monitor-create-form-text75 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-text76 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-container96 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.monitor-create-form-navlink {
  display: contents;
}
.monitor-create-form-container97 {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}
.monitor-create-form-icon2 {
  width: 18px;
  height: 18px;
}
.monitor-create-form-text77 {
  text-transform: none;
  text-decoration: none;
}
.monitor-create-form-navlink1 {
  display: contents;
}
.monitor-create-form-container98 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.monitor-create-form-container98:hover {
  background-color: #462365;
}
.monitor-create-form-icon4 {
  width: 18px;
  height: 18px;
}
.monitor-create-form-text78 {
  text-transform: none;
  text-decoration: none;
}
