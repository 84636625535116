.popup-popup {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: none;
  position: relative;
  align-items: flex-start;
  backdrop-filter: blur(5px);
  background-color: rgba(20, 2, 30, 0.1);
}
.popup-container {
  top: 70px;
  flex: 0 0 auto;
  right: 20px;
  width: 367px;
  height: 364px;
  display: flex;
  position: absolute;
  box-shadow: 0px 0px 10px 0px rgba(72, 70, 70, 0.37);
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.popup-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
