.toggle-box-container {
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.toggle-box-container1 {
  flex: 0 0 auto;
  width: 50px;
  height: 25px;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: 0.1s;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 2px;
  border-radius: 25px;
  padding-right: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-white);
}
.toggle-box-container1:hover {
  background-color: #f3f3f3;
}
.toggle-box-container2 {
  flex: 0 0 auto;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(223, 223, 223, 0.6);
}
.toggle-box-container3 {
  flex: 0 0 auto;
  width: 50px;
  height: 25px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-end;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 2px;
  border-radius: 25px;
  padding-right: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-main-1);
}
.toggle-box-container4 {
  flex: 0 0 auto;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}


