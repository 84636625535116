.settings-notification-channel-item-settings-notification-channel-item {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-notification-channel-item-container {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-notification-channel-item-container1 {
  cursor: pointer;
  position: relative;
}
.settings-notification-channel-item-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-main-1);
}
.settings-notification-channel-item-icon {
  width: 15px;
  height: 15px;
}
.settings-notification-channel-item-container3 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
}
.settings-notification-channel-item-container4 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-notification-channel-item-container5 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.settings-notification-channel-item-text1 {
  font-weight: 300;
}
