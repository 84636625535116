.dashboard-overview-total-contacts-dashboard-obreview-total-contacts {
  flex: 1;
  height: 299px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.dashboard-overview-total-contacts-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}
.dashboard-overview-total-contacts-container1 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dashboard-overview-total-contacts-text {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.dashboard-overview-total-contacts-text1 {
  font-size: 18px;
}
.dashboard-overview-total-contacts-container2 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.dashboard-overview-total-contacts-text2 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.dashboard-overview-total-contacts-text3 {
  font-style: normal;
  font-weight: 700;
}
.dashboard-overview-total-contacts-container3 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.dashboard-overview-total-contacts-ul {
  flex: 1;
  overflow: scroll;
}
