.charge-wallet-item-charge-wallet-item {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.charge-wallet-item-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.charge-wallet-item-container1 {
  flex: 0.9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.charge-wallet-item-container2 {
  flex: 0.9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.charge-wallet-item-container3 {
  gap: 5px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 5px;
}
.charge-wallet-item-text2 {
  font-size: 12px;
}
.charge-wallet-item-container4 {
  gap: 5px;
  color: var(--dl-color-traffls-color-300);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: rgba(88, 179, 127, 0.4);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 5px;
  background-color: rgba(88, 179, 127, 0.2);
}
.charge-wallet-item-text3 {
  font-size: 12px;
}
.charge-wallet-item-container5 {
  gap: 5px;
  color: var(--dl-color-traffls-red);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 5px;
  background-color: rgba(178, 68, 68, 0.2);
}
.charge-wallet-item-text4 {
  font-size: 12px;
}
.charge-wallet-item-container6 {
  gap: 10px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.charge-wallet-item-container7 {
  fill: var(--dl-color-traffls-red);
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(178, 68, 68, 0.1);
}
.charge-wallet-item-icon {
  width: 18px;
  height: 18px;
}
.charge-wallet-item-container8 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.charge-wallet-item-icon2 {
  width: 18px;
  height: 18px;
}
.charge-wallet-item-text5 {
  text-transform: none;
}
