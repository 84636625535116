.sessiom-cookie-group-item-cookie-group-item {
  width: 300px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.sessiom-cookie-group-item-container {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.sessiom-cookie-group-item-icon {
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.598);
  width: 18px;
  height: 18px;
  transition: 0.1s;
}
.sessiom-cookie-group-item-icon:hover {
  fill: var(--dl-color-traffls-color-100);
}
.sessiom-cookie-group-item-container1 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  padding-top: 20px;
  flex-direction: column;
}
.sessiom-cookie-group-item-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.sessiom-cookie-group-item-container3 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sessiom-cookie-group-item-text1 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.sessiom-cookie-group-item-text2 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.sessiom-cookie-group-item-container4 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.sessiom-cookie-group-item-text3 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.sessiom-cookie-group-item-text4 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.sessiom-cookie-group-item-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.sessiom-cookie-group-item-text5 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.sessiom-cookie-group-item-text6 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
