.charge-wallet-component-charge-wallet {
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.charge-wallet-component-container {
  gap: 50px;
  width: 100%;
  display: flex;
  padding: 30px;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.charge-wallet-component-container1 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.charge-wallet-component-container2 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.charge-wallet-component-text {
  font-size: 16px;
}
.charge-wallet-component-text1 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.charge-wallet-component-container3 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.charge-wallet-component-container4 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.charge-wallet-component-monitor-create-monitor-monitor-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.charge-wallet-component-navlink {
  display: contents;
}
.charge-wallet-component-container5 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.charge-wallet-component-container5:hover {
  background-color: #462365;
}
.charge-wallet-component-icon {
  width: 18px;
  height: 18px;
}
.charge-wallet-component-text2 {
  text-transform: none;
}
.charge-wallet-component-navlink1 {
  display: contents;
}
.charge-wallet-component-container6 {
  gap: 10px;
  fill: var(--dl-color-traffls-main-1);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
}
.charge-wallet-component-icon2 {
  width: 18px;
  height: 18px;
}
.charge-wallet-component-text3 {
  text-transform: none;
}
.charge-wallet-component-container7 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.charge-wallet-component-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.charge-wallet-component-text4 {
  font-size: 12px;
}
.charge-wallet-component-ul {
  overflow: scroll;
}

