.create-channel-group-create-channel-group {
  gap: 50px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 30px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.create-channel-group-container {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.create-channel-group-container01 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.create-channel-group-text {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.create-channel-group-text1 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.create-channel-group-container02 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.create-channel-group-chanel-creat-group-group-name-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.create-channel-group-container03 {
  gap: 50px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.create-channel-group-container04 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.create-channel-group-text2 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.create-channel-group-text3 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.create-channel-group-channel-creat-group-contacts {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.create-channel-group-container05 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.create-channel-group-container06 {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.create-channel-group-container07 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.create-channel-group-text4 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.create-channel-group-container08 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.create-channel-group-text5 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.create-channel-group-container09 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
}
.create-channel-group-li {
  padding-bottom: 0px;
}
.create-channel-group-li1 {
  padding-bottom: 0px;
}
.create-channel-group-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-channel-group-container11 {
  padding-left: 20px;
  padding-right: 20px;
}
.create-channel-group-icon {
  width: 18px;
  height: 18px;
}
.create-channel-group-text6 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.create-channel-group-container12 {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.create-channel-group-container13 {
  padding-left: 20px;
  padding-right: 20px;
}
.create-channel-group-icon2 {
  width: 18px;
  height: 18px;
}
.create-channel-group-text7 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.create-channel-group-container14 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
  cursor: pointer;
}
.create-channel-group-container14:hover {
  background-color: #462365;
}
.create-channel-group-icon4 {
  width: 18px;
  height: 18px;
}
.create-channel-group-text8 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
