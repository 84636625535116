.savings-plans-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.savings-plans-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.savings-plans-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.savings-plans-content1 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: center;
  background-color: var(--dl-color-traffls-bg-1);
}
.savings-plans-lottie-node {
  width: 300px;
  height: 300px;
}
.savings-plans-text {
  opacity: 0.7;
  font-size: 22px;
}
