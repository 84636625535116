.channel-add-contact-contact-info-channel-add-contact-contact-info {
  gap: 50px;
  width: 100%;
  display: flex;
  padding: 30px;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.channel-add-contact-contact-info-container {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.channel-add-contact-contact-info-container01 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.channel-add-contact-contact-info-text {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.channel-add-contact-contact-info-text01 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.channel-add-contact-contact-info-container02 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.channel-add-contact-contact-info-container03 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.channel-add-contact-contact-info-channel-add-contact-contact-info-first-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.channel-add-contact-contact-info-container04 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.channel-add-contact-contact-info-channel-add-contact-contact-info-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.channel-add-contact-contact-info-container05 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.channel-add-contact-contact-info-channel-add-contact-contact-info-email {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.channel-add-contact-contact-info-container06 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.channel-add-contact-contact-info-container07 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.channel-add-contact-contact-info-text02 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.channel-add-contact-contact-info-text03 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.channel-add-contact-contact-info-container08 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.channel-add-contact-contact-info-container09 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.channel-add-contact-contact-info-container10 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.channel-add-contact-contact-info-container11 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.channel-add-contact-contact-info-text04 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.channel-add-contact-contact-info-text05 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.channel-add-contact-contact-info-container12 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.channel-add-contact-contact-info-container13 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.channel-add-contact-contact-info-text06 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.channel-add-contact-contact-info-text07 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.channel-add-contact-contact-info-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.channel-add-contact-contact-info-navlink {
  display: contents;
}
.channel-add-contact-contact-info-container15 {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}
.channel-add-contact-contact-info-icon {
  width: 18px;
  height: 18px;
}
.channel-add-contact-contact-info-text08 {
  text-transform: none;
  text-decoration: none;
}
.channel-add-contact-contact-info-navlink1 {
  display: contents;
}
.channel-add-contact-contact-info-container16 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.channel-add-contact-contact-info-container16:hover {
  background-color: #462365;
}
.channel-add-contact-contact-info-icon2 {
  width: 18px;
  height: 18px;
}
.channel-add-contact-contact-info-text09 {
  text-transform: none;
}
