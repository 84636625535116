.billing-overview-costs-billing-overview-costs {
  flex: 0 0 auto;
  width: 352px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.billing-overview-costs-container {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.billing-overview-costs-container01 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.billing-overview-costs-container02 {
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.billing-overview-costs-container03 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.billing-overview-costs-text01 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-text02 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-container04 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.billing-overview-costs-text03 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-container05 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billing-overview-costs-text04 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-text05 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-container06 {
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.billing-overview-costs-container07 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.billing-overview-costs-text06 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-text07 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-container08 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.billing-overview-costs-text08 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-container09 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billing-overview-costs-text09 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-text10 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-container10 {
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.billing-overview-costs-container11 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.billing-overview-costs-text11 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-text12 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-container12 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.billing-overview-costs-text13 {
  opacity: 0.6;
  font-size: 12px;
}
.billing-overview-costs-container13 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billing-overview-costs-text14 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.billing-overview-costs-text15 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

