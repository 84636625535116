.events-create-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.events-create-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.events-create-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.events-create-create-monitor {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.events-create-container1 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.events-create-navlink {
  display: contents;
}
.events-create-container2 {
  gap: 10px;
  fill: var(--dl-color-traffls-color-500);
  color: var(--dl-color-traffls-color-500);
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: var(--dl-color-traffls-color-000);
}
.events-create-icon {
  width: 18px;
  height: 18px;
}
.events-create-text {
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.events-create-navlink1 {
  display: contents;
}
.events-create-container3 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.events-create-icon2 {
  width: 18px;
  height: 18px;
}
.events-create-text1 {
  text-transform: none;
}
