.events-contacts-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.events-contacts-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.events-contacts-content {
  flex: 1;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.events-contacts-employee-overview-list {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.events-contacts-container01 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.events-contacts-container02 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.events-contacts-text {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.events-contacts-container03 {
  gap: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.events-contacts-text01 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-text02 {
  font-weight: 500;
}
.events-contacts-container04 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.events-contacts-container05 {
  gap: 10px;
  flex: 0 0 auto;
  width: 350px;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  flex-direction: row;
  background-color: var(--dl-color-traffls-color-000);
}
.events-contacts-icon {
  width: 18px;
  height: 18px;
  opacity: 0.6;
}
.events-contacts-employees-overview-find {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.events-contacts-navlink {
  display: contents;
}
.events-contacts-container06 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.events-contacts-icon2 {
  width: 18px;
  height: 18px;
}
.events-contacts-text03 {
  text-transform: none;
}
.events-contacts-container07 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.events-contacts-container08 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.events-contacts-container09 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.events-contacts-text04 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-contacts-container10 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.events-contacts-text05 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-contacts-container11 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-contacts-text06 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-contacts-container12 {
  flex: 0 0 auto;
  width: 160px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-contacts-ul {
  flex: 1;
  overflow: auto;
}
.events-contacts-list-details-list-name-item {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.events-contacts-container13 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
}
.events-contacts-container14 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.events-contacts-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.events-contacts-container15 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-contacts-text08 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-container16 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-contacts-container17 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.events-contacts-container18 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.events-contacts-text10 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-text11 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-text12 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-container19 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-contacts-container20 {
  gap: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.events-contacts-text13 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-container21 {
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  flex-direction: row;
  padding-bottom: 5px;
}
.events-contacts-text14 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-contacts-text15 {
  fill: var(--dl-color-traffls-color-100);
  color: var(--dl-color-traffls-color-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.events-contacts-container22 {
  gap: 10px;
  flex: 0 0 auto;
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.events-contacts-container23 {
  fill: var(--dl-color-traffls-red);
  flex: 0 0 auto;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(178, 68, 68, 0.1);
}
.events-contacts-icon4 {
  width: 18px;
  height: 18px;
}
.events-contacts-container24 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: #542f7a;
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  background-color: #542f7a;
}
.events-contacts-text16 {
  font-weight: 300;
}
