.dashboard-overview-preference-contacts-list-dashboard-overview-preference-contacts {
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.dashboard-overview-preference-contacts-list-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-overview-preference-contacts-list-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.dashboard-overview-preference-contacts-list-container02 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-overview-preference-contacts-list-text {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-sort-by-drop-down-component {
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-overview-preference-contacts-list-container03 {
  gap: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-overview-preference-contacts-list-text01 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-none {
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-text02 {
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-text03 {
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-text04 {
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-text05 {
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.dashboard-overview-preference-contacts-list-container04 {
  top: 0px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  z-index: 90;
  position: absolute;
  align-items: flex-start;
  padding-top: 20px;
}
.dashboard-overview-preference-contacts-list-container05 {
  flex: 0 0 auto;
  width: 272px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(212, 212, 212, 0.25);
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.dashboard-overview-preference-contacts-list-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.dashboard-overview-preference-contacts-list-container07 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.dashboard-overview-preference-contacts-list-container08 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.dashboard-overview-preference-contacts-list-icon {
  width: 10px;
  height: 10px;
}
.dashboard-overview-preference-contacts-list-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.dashboard-overview-preference-contacts-list-container10 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.dashboard-overview-preference-contacts-list-container11 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.dashboard-overview-preference-contacts-list-icon02 {
  width: 10px;
  height: 10px;
}
.dashboard-overview-preference-contacts-list-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.dashboard-overview-preference-contacts-list-container13 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.dashboard-overview-preference-contacts-list-container14 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.dashboard-overview-preference-contacts-list-icon04 {
  width: 10px;
  height: 10px;
}
.dashboard-overview-preference-contacts-list-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.dashboard-overview-preference-contacts-list-container16 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.dashboard-overview-preference-contacts-list-container17 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.dashboard-overview-preference-contacts-list-icon06 {
  width: 10px;
  height: 10px;
}
.dashboard-overview-preference-contacts-list-container18 {
  gap: 10px;
  flex: 0 0 auto;
  width: 350px;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  flex-direction: row;
  background-color: var(--dl-color-traffls-color-000);
}
.dashboard-overview-preference-contacts-list-icon08 {
  width: 18px;
  height: 18px;
  opacity: 0.6;
}
.dashboard-overview-preference-contacts-list-dashboard-overview-preferencecontacts-find {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.dashboard-overview-preference-contacts-list-container19 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.dashboard-overview-preference-contacts-list-container20 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.dashboard-overview-preference-contacts-list-container21 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.dashboard-overview-preference-contacts-list-text10 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.dashboard-overview-preference-contacts-list-container22 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-overview-preference-contacts-list-text11 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.dashboard-overview-preference-contacts-list-container23 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dashboard-overview-preference-contacts-list-text12 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.dashboard-overview-preference-contacts-list-container24 {
  flex: 0 0 auto;
  width: 160px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dashboard-overview-preference-contacts-list-ul {
  flex: 1;
  overflow: auto;
}

