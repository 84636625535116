.list-contact-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.list-contact-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.list-contact-content {
  flex: 1;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.list-contact-content1 {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.list-contact-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.list-contact-container02 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.list-contact-navlink {
  display: contents;
}
.list-contact-container03 {
  text-decoration: none;
}
.list-contact-icon {
  width: 18px;
  height: 18px;
}
.list-contact-navlink1 {
  display: contents;
}
.list-contact-container04 {
  gap: 10px;
  fill: var(--dl-color-traffls-red);
  color: var(--dl-color-traffls-red);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: rgba(178, 68, 68, 0.1);
}
.list-contact-icon2 {
  width: 18px;
  height: 18px;
}
.list-contact-container05 {
  gap: 50px;
  width: 100%;
  display: flex;
  padding: 30px;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.list-contact-container06 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.list-contact-container07 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.list-contact-text02 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.list-contact-text03 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-contact-container08 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.list-contact-container09 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.list-contact-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-contact-container10 {
  gap: 40px;
  display: flex;
  flex-direction: row;
}
.list-contact-container11 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.list-contact-text04 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.list-contact-text05 {
  opacity: 0.6;
}
.list-contact-container12 {
  gap: 10px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.list-contact-text06 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.list-contact-text07 {
  opacity: 0.6;
}
.list-contact-container13 {
  gap: 10px;
  flex: 0 0 auto;
  width: 257px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.list-contact-text08 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.list-contact-text09 {
  opacity: 0.6;
}
.list-contact-container14 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.list-contact-container15 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.list-contact-text10 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.list-contact-text11 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-contact-container16 {
  gap: 20px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.list-contact-container17 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.list-contact-list-contact-contact-details-first-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.list-contact-container18 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.list-contact-list-contact-contact-details-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.list-contact-container19 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.list-contact-list-contact-contact-details-linkedin-url {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.list-contact-container20 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.list-contact-container21 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.list-contact-text12 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.list-contact-text13 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-contact-container22 {
  gap: 20px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.list-contact-container23 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.list-contact-list-contact-company-details-company-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.list-contact-container24 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.list-contact-list-contact-company-details-linkedin-url {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.list-contact-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-contact-navlink2 {
  display: contents;
}
.list-contact-container26 {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}
.list-contact-icon4 {
  width: 18px;
  height: 18px;
}
.list-contact-text14 {
  text-transform: none;
  text-decoration: none;
}
.list-contact-navlink3 {
  display: contents;
}
.list-contact-container27 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.list-contact-container27:hover {
  background-color: #462365;
}
.list-contact-icon6 {
  width: 18px;
  height: 18px;
}
.list-contact-text15 {
  text-transform: none;
}
.list-contact-popup {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: none;
  position: absolute;
  align-items: flex-start;
  backdrop-filter: blur(5px);
  background-color: rgba(20, 2, 30, 0.1);
}
.list-contact-container28 {
  top: 70px;
  flex: 0 0 auto;
  right: 20px;
  width: 367px;
  height: 364px;
  display: flex;
  position: absolute;
  box-shadow: 0px 0px 10px 0px rgba(72, 70, 70, 0.37);
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.list-contact-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
