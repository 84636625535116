.events-details-component-monitor-details-component {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 20px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.events-details-component-container {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 10px;
}
.events-details-component-container01 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
}
.events-details-component-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.events-details-component-container03 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-text {
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.events-details-component-text01 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-text02 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-container05 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.events-details-component-text03 {
  text-transform: none;
}
.events-details-component-text04 {
  text-transform: none;
}
.events-details-component-container07 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
}
.events-details-component-text05 {
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
}
.events-details-component-text06 {
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
}
.events-details-component-container09 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-container10 {
  gap: 10PX;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.events-details-component-container11 {
  gap: 10px;
  color: #58b37f;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: #58b37f;
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-end;
  background-color: rgba(88, 179, 127, 0.3);
}
.events-details-component-container12 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: #58b37f;
}
.events-details-component-text07 {
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-container13 {
  gap: 10px;
  color: var(--dl-color-traffls-color-5001);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-5001);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-end;
  background-color: rgba(238, 114, 34, 0.3);
}
.events-details-component-container14 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-color-5001);
}
.events-details-component-text08 {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
}
.events-details-component-container15 {
  gap: 10px;
  color: var(--dl-color-theme-neutral-dark);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-end;
}
.events-details-component-container16 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-color-400);
}
.events-details-component-text09 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-container17 {
  gap: 10px;
  color: var(--dl-color-traffls-red);
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: rgba(178, 68, 68, 0.3);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-end;
  background-color: rgba(178, 68, 68, 0.2);
}
.events-details-component-container18 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-traffls-red);
}
.events-details-component-text10 {
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.events-details-component-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.events-details-component-text11 {
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-main-1);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
.events-details-component-text12 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-details-component-container20 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-details-component-container21 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
}
.events-details-component-container22 {
  gap: 10px;
  width: 190px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.events-details-component-text13 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-details-component-container23 {
  width: 50p;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.events-details-component-text14 {
  font-style: normal;
  font-weight: 500;
}
.events-details-component-container24 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: #dfdfdf;
  border-width: 1px;
  border-radius: 5px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 0.5);
}
.events-details-component-container25 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #502b88;
}
.events-details-component-container26 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 5px;
}
.events-details-component-container27 {
  gap: 10px;
  width: 190px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.events-details-component-text15 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-details-component-container28 {
  width: 50p;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.events-details-component-text16 {
  font-style: normal;
  font-weight: 500;
}
.events-details-component-container29 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: #dfdfdf;
  border-width: 1px;
  border-radius: 5px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 0.5);
}
.events-details-component-container30 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #502b88;
}
.events-details-component-container31 {
  gap: 20px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-height: 550px;
  min-height: 350px;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-container32 {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.events-details-component-container33 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.events-details-component-container34 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.events-details-component-container35 {
  gap: 5px;
  color: var(--dl-color-traffls-color-000);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-start;
  background-color: var(--dl-color-traffls-main-1);
}
.events-details-component-text17 {
  line-height: 1;
}
.events-details-component-container36 {
  gap: 5px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-start;
}
.events-details-component-text18 {
  line-height: 1;
}
.events-details-component-container37 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.events-details-component-container38 {
  gap: 5px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-start;
}
.events-details-component-text19 {
  line-height: 1;
}
.events-details-component-container39 {
  gap: 5px;
  color: var(--dl-color-traffls-color-000);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: flex-start;
  background-color: var(--dl-color-traffls-main-1);
}
.events-details-component-text20 {
  line-height: 1;
}
.events-details-component-container40 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.events-details-component-text21 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}
.events-details-component-text22 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}
.events-details-component-container41 {
  gap: 10px;
  flex: 0 0 auto;
  width: 350px;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  flex-direction: row;
}
.events-details-component-icon {
  width: 18px;
  height: 18px;
  opacity: 0.6;
}
.events-details-component-monitor-details-contacts-find {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.events-details-component-container42 {
  width: 100%;
  height: 350px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #F6F7F9;
}
.events-details-component-container43 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.events-details-component-container44 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
}
.events-details-component-text23 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container45 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.events-details-component-text24 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container46 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-text25 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container47 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-details-component-ul {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  background-color: var(--dl-color-traffls-white);
}
.events-details-component-container48 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  height: 346px;
  display: none;
  overflow: scroll;
  align-items: flex-start;
  flex-direction: column;
}
.events-details-component-container49 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container50 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container51 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container52 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container53 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container54 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container55 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
  background-color: #D9D9D9;
}
.events-details-component-container56 {
  width: 100%;
  height: 350px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #F6F7F9;
}
.events-details-component-container57 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.events-details-component-container58 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.events-details-component-text26 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container59 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.events-details-component-text27 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container60 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-text28 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-container61 {
  flex: 0.7;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-details-component-text29 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.events-details-component-ul1 {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  background-color: var(--dl-color-traffls-white);
}
.events-details-component-container62 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.events-details-component-container63 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.events-details-component-container64 {
  gap: 10px;
  fill: var(--dl-color-traffls-color-100);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  background-color: #ffffff;
}
.events-details-component-icon02 {
  width: 18px;
  height: 18px;
}
.events-details-component-text30 {
  fill: #542f7a;
  color: rgb(84, 47, 122);
  font-style: normal;
  font-weight: 400;
}
.events-details-component-container65 {
  gap: 10px;
  fill: var(--dl-color-traffls-color-100);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  background-color: #ffffff;
}
.events-details-component-icon04 {
  width: 18px;
  height: 18px;
}
.events-details-component-text31 {
  fill: #542f7a;
  color: rgb(84, 47, 122);
  font-style: normal;
  font-weight: 400;
}
.events-details-component-container66 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
}
.events-details-component-navlink {
  display: contents;
}
.events-details-component-container67 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #ffffff;
}
.events-details-component-icon06 {
  fill: #542f7a;
  width: 20px;
  height: 20px;
}
.events-details-component-text32 {
  fill: #542f7a;
  color: #542f7a;
  font-style: normal;
  font-weight: 400;
}
.events-details-component-navlink1 {
  display: contents;
}
.events-details-component-container68 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.events-details-component-icon08 {
  width: 18px;
  height: 18px;
}
.events-details-component-root-class-name {
  flex: 0 0 auto;
}
