.channels-overview-list-channels-overview-list {
  gap: 10px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.channels-overview-list-container {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: end;
}
.channels-overview-list-text {
  font-size: 18px;
}
.channels-overview-list-navlink {
  display: contents;
}
.channels-overview-list-container1 {
  text-decoration: none;
}
.channels-overview-list-icon {
  width: 18px;
  height: 18px;
}
.channels-overview-list-container2 {
  flex: 1;
  width: 100%;
  height: 526px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.channels-overview-list-container3 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.channels-overview-list-container4 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.channels-overview-list-text2 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.channels-overview-list-container5 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.channels-overview-list-text3 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.channels-overview-list-container6 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.channels-overview-list-text4 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.channels-overview-list-container7 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.channels-overview-list-ul {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  background-color: var(--dl-color-traffls-white);
}
