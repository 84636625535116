.events-details-event-item-monitor-details-contacts-item {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.events-details-event-item-container {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.events-details-event-item-container01 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.events-details-event-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.events-details-event-item-container02 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-details-event-item-text1 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.events-details-event-item-container03 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-details-event-item-container04 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.events-details-event-item-container05 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-details-event-item-container06 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.events-details-event-item-container07 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.events-details-event-item-container08 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
}
.events-details-event-item-navlink {
  display: contents;
}
.events-details-event-item-container09 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  width: 130px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
}
.events-details-event-item-text4 {
  fill: #542f7a;
  color: rgb(84, 47, 122);
  font-style: normal;
  font-weight: 400;
}
.events-details-event-item-navlink1 {
  display: contents;
}
.events-details-event-item-container10 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  width: 160px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}

