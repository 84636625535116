.channels-overview-contacts-item-session-cookie-overview-channels-item {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--dl-color-traffls-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.channels-overview-contacts-item-container {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
}
.channels-overview-contacts-item-container1 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.channels-overview-contacts-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.channels-overview-contacts-item-container2 {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.channels-overview-contacts-item-text1 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.channels-overview-contacts-item-container3 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.channels-overview-contacts-item-container4 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.channels-overview-contacts-item-container5 {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.channels-overview-contacts-item-container6 {
  gap: 10px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.channels-overview-contacts-item-container7 {
  fill: var(--dl-color-traffls-red);
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(178, 68, 68, 0.1);
}
.channels-overview-contacts-item-icon {
  width: 18px;
  height: 18px;
}
.channels-overview-contacts-item-container8 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
  cursor: pointer;
}
