.sort-byv1-drop-down-component-sort-by-drop-down-component {
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.sort-byv1-drop-down-component-container {
  gap: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: flex-start;
}
.sort-byv1-drop-down-component-text {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}
.sort-byv1-drop-down-component-text1 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
.sort-byv1-drop-down-component-container01 {
  top: 0px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  z-index: 90;
  position: absolute;
  align-items: flex-start;
  padding-top: 20px;
}
.sort-byv1-drop-down-component-container02 {
  flex: 0 0 auto;
  width: 272px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(212, 212, 212, 0.25);
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.sort-byv1-drop-down-component-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container04 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container05 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.sort-byv1-drop-down-component-container06 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.sort-byv1-drop-down-component-icon {
  width: 10px;
  height: 10px;
}
.sort-byv1-drop-down-component-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container08 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container09 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.sort-byv1-drop-down-component-container10 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.sort-byv1-drop-down-component-icon2 {
  width: 10px;
  height: 10px;
}
.sort-byv1-drop-down-component-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container12 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container13 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.sort-byv1-drop-down-component-container14 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.sort-byv1-drop-down-component-icon4 {
  width: 10px;
  height: 10px;
}
.sort-byv1-drop-down-component-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container16 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-byv1-drop-down-component-container17 {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
}
.sort-byv1-drop-down-component-container18 {
  fill: var(--dl-color-traffls-color-000);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-100);
}
.sort-byv1-drop-down-component-icon6 {
  width: 10px;
  height: 10px;
}


