.confirmationBox-Main{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.btn-container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.btn-delete{
    height: 40px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(178, 68, 68, 0.1);
    border-color: rgba(178, 68, 68, 0.4);
    border-width: 1px;
    border-radius: 8px;
    color: #B24444;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.btn-delete:hover{
    background-color: #B24444;
    color: white;
}


.btn-cancel{
    height: 40px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    border-color: #DFDFDF;
    border-width: 1px;
    border-radius: 8px;
    color: rgba(25, 24, 24, 0.7);
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.btn-cancel:hover{
    background-color: #dfdfdf70;
}