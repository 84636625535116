.list-overview-item-list-overview-item {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: 20px;
  background-color: var(--dl-color-traffls-color-000);
}
.list-overview-item-container {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.list-overview-item-text {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.list-overview-item-container01 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.list-overview-item-text01 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-overview-item-text02 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-overview-item-container02 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.list-overview-item-container03 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.list-overview-item-text03 {
  text-transform: none;
}
.list-overview-item-text04 {
  text-transform: none;
}
.list-overview-item-container04 {
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.list-overview-item-text05 {
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
}
.list-overview-item-text06 {
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-main-1);
  font-style: normal;
  font-weight: 500;
}
.list-overview-item-container05 {
  gap: 10px;
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.list-overview-item-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.list-overview-item-text07 {
  text-transform: none;
}
.list-overview-item-text08 {
  text-transform: none;
}
.list-overview-item-container07 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.list-overview-item-text09 {
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.list-overview-item-text10 {
  fill: var(--dl-color-traffls-red);
  color: var(--dl-color-traffls-red);
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.list-overview-item-container08 {
  gap: 10PX;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.list-overview-item-container09 {
  fill: var(--dl-color-traffls-red);
  flex: 0 0 auto;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(178, 68, 68, 0.1);
}
.list-overview-item-container09:hover {
  fill: var(--dl-color-traffls-color-000);
  background-color: var(--dl-color-traffls-red);
}
.list-overview-item-icon {
  width: 18px;
  height: 18px;
}
.list-overview-item-navlink {
  display: contents;
}
.list-overview-item-container10 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
