/* src/components/Notification.css */
.toast-message {
    display: flex;
    align-items: center;
  }
  
  .toast-message .material-icons {
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
  }
  
  .Toastify__spinner {
    border-top-color: #3498db !important; /* Farbe des oberen Teils des Ladebalkens */
    border-bottom-color: #3498db !important; /* Farbe des unteren Teils des Ladebalkens */
    border-left-color: #f3f3f3 !important; /* Farbe des linken Teils des Ladebalkens */
    border-right-color: #f3f3f3 !important; /* Farbe des rechten Teils des Ladebalkens */
  }