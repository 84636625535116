.dashboard-overview-contacts-item-dashboard-overview-contacts-item-white {
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.dashboard-overview-contacts-item-text {
  font-size: 12px;
}
.dashboard-overview-contacts-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-overview-contacts-item-container1 {
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  padding-bottom: 5px;
  justify-content: space-between;
}
.dashboard-overview-contacts-item-text1 {
  font-size: 12px;
}
.dashboard-overview-contacts-item-text2 {
  font-size: 12px;
}
