.session-cookie-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.session-cookie-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.session-cookie-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-content1 {
  gap: 20px;
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
