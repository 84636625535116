.log-overview-list-log-overview-list {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.log-overview-list-container {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.log-overview-list-ul {
  flex: 1;
  overflow: scroll;
}
