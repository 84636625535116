.list-details-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.list-details-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.list-details-content {
  flex: 1;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.list-details-content1 {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.list-details-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.list-details-container02 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.list-details-navlink {
  display: contents;
}
.list-details-container03 {
  text-decoration: none;
}
.list-details-icon {
  width: 18px;
  height: 18px;
}
.list-details-navlink1 {
  display: contents;
}
.list-details-container04 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.list-details-icon2 {
  width: 18px;
  height: 18px;
}
.list-details-text01 {
  text-transform: none;
}
.list-details-navlink2 {
  display: contents;
}
.list-details-container05 {
  gap: 10px;
  fill: var(--dl-color-traffls-red);
  color: var(--dl-color-traffls-red);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: rgba(178, 68, 68, 0.4);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: rgba(178, 68, 68, 0.1);
}
.list-details-icon4 {
  width: 18px;
  height: 18px;
}
.list-details-container06 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.list-details-container07 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.list-details-text03 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}
.list-details-text04 {
  opacity: 0.6;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}
.list-details-text06 {
  font-weight: 700;
}
.list-details-text07 {
  opacity: 0.6;
  line-height: 1;
}
.list-details-text08 {
  font-style: normal;
  font-weight: 300;
}
.list-details-text09 {
  font-weight: 700;
}
.list-details-text10 {
  font-weight: 300;
}
.list-details-text11 {
  font-weight: 300;
}
.list-details-container08 {
  gap: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.list-details-container09 {
  gap: 10px;
  flex: 0 0 auto;
  width: 350px;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  flex-direction: row;
  background-color: var(--dl-color-traffls-color-000);
}
.list-details-icon6 {
  width: 18px;
  height: 18px;
  opacity: 0.6;
}
.list-details-list-details-list-name-find {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.list-details-container10 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-color-000);
}
.list-details-container11 {
  gap: 20px;
  color: var(--dl-color-traffls-white);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(135deg, rgb(80, 43, 136) 0.00%,rgba(168, 133, 221, 0.99) 100.00%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.list-details-container12 {
  gap: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.list-details-text12 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.list-details-container13 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.list-details-text13 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.list-details-container14 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.list-details-text14 {
  opacity: 0.6;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.list-details-container15 {
  flex: 0 0 auto;
  width: 160px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.list-details-ul {
  flex: 1;
  overflow: auto;
}
