.menu-bar-menu-bar {
  flex: 0 0 auto;
  width: 270px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-menu-bar-top {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
}
.menu-bar-image {
  width: 30px;
  object-fit: cover;
}
.menu-bar-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.menu-bar-container {
  gap: 40px;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: 40px;
  flex-direction: column;
}
.menu-bar-container01 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
}
.menu-bar-container02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-navlink {
  display: contents;
}
.menu-bar-container03 {
  text-decoration: none;
}
.menu-bar-icon {
  width: 24px;
  height: 20px;
}
.menu-bar-navlink01 {
  display: contents;
}
.menu-bar-container04 {
  text-decoration: none;
}
.menu-bar-icon02 {
  width: 24px;
  height: 20px;
}
.menu-bar-container05 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
}
.menu-bar-text03 {
  font-weight: 300;
}
.menu-bar-navlink02 {
  display: contents;
}
.menu-bar-container06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon04 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon06 {
  width: 24px;
  height: 20px;
}
.menu-bar-container09 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-icon08 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon10 {
  width: 24px;
  height: 20px;
}
.menu-bar-navlink03 {
  display: contents;
}
.menu-bar-container12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon12 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon14 {
  width: 24px;
  height: 20px;
}
.menu-bar-navlink04 {
  display: contents;
}
.menu-bar-container15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon16 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon18 {
  width: 24px;
  height: 20px;
}
.menu-bar-navlink05 {
  display: contents;
}
.menu-bar-container18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon20 {
  width: 20px;
  height: 20px;
}
.menu-bar-icon22 {
  width: 20px;
  height: 20px;
}
.menu-bar-navlink06 {
  display: contents;
}
.menu-bar-container21 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon24 {
  width: 20px;
  height: 20px;
}
.menu-bar-icon26 {
  width: 20px;
  height: 20px;
}
.menu-bar-navlink07 {
  display: contents;
}
.menu-bar-container24 {
  display: none;
  text-decoration: none;
}
.menu-bar-icon28 {
  width: 24px;
  height: 24px;
}
.menu-bar-container25 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
}
.menu-bar-text17 {
  font-weight: 300;
}
.menu-bar-navlink08 {
  display: contents;
}
.menu-bar-container26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon30 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon32 {
  width: 24px;
  height: 20px;
}
.menu-bar-navlink09 {
  display: contents;
}
.menu-bar-container29 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon34 {
  width: 20px;
  height: 20px;
}
.menu-bar-icon36 {
  width: 20px;
  height: 20px;
}
.menu-bar-navlink10 {
  display: contents;
}
.menu-bar-container32 {
  display: none;
  text-decoration: none;
}
.menu-bar-icon38 {
  width: 24px;
  height: 24px;
}
.menu-bar-container33 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
}
.menu-bar-text23 {
  font-style: normal;
  font-weight: 300;
}
.menu-bar-navlink11 {
  display: contents;
}
.menu-bar-container34 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.menu-bar-icon40 {
  width: 24px;
  height: 20px;
}
.menu-bar-icon42 {
  width: 24px;
  height: 20px;
}
.menu-bar-container37 {
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-container38 {
  text-decoration: none;
}
.menu-bar-icon44 {
  width: 24px;
  height: 24px;
}
.menu-bar-container39 {
  text-decoration: none;
}
.menu-bar-icon47 {
  width: 24px;
  height: 24px;
}
.menu-bar-container40 {
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-container41 {
  text-decoration: none;
}
.menu-bar-icon50 {
  width: 24px;
  height: 20px;
}
.menu-bar-container42 {
  text-decoration: none;
}
.menu-bar-icon52 {
  width: 24px;
  height: 20px;
}
.menu-bar-container43 {
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.menu-bar-container44 {
  text-decoration: none;
}
.menu-bar-icon54 {
  width: 24px;
  height: 24px;
}
.menu-bar-container45 {
  text-decoration: none;
}
.menu-bar-icon56 {
  width: 24px;
  height: 24px;
}
.menu-bar-navlink18 {
  display: contents;
}
.menu-bar-container46 {
  display: none;
  text-decoration: none;
}
.menu-bar-icon58 {
  width: 24px;
  height: 20px;
}
