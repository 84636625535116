.settings-overview-settings-overview-account-owner-company-info {
  gap: 50px;
  width: 100%;
  display: flex;
  padding: 30px;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.settings-overview-container {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-overview-container01 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-overview-text {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.settings-overview-text01 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container02 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.settings-overview-container03 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-input {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container04 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-account-owner-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container05 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-account-owner-email {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container06 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-account-owner-email1 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container07 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-account-owner-country-code {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container08 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-overview-container09 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-overview-text02 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.settings-overview-text03 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container10 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.settings-overview-container11 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-company-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container12 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-address-line1 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container13 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-address-line2 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container14 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-country-code {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container15 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-tax-id {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container16 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-company-info-linkedin-url {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container17 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-overview-container18 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-overview-text04 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.settings-overview-text05 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container19 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.settings-overview-container20 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact1-first-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container21 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact1-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container22 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact1-email {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container23 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.settings-overview-container24 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.settings-overview-container25 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-overview-text06 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.settings-overview-text07 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container26 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.settings-overview-container27 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-overview-text08 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.settings-overview-text09 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container28 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-overview-container29 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-overview-text10 {
  fill: #502b88;
  color: rgb(80, 43, 136);
  font-size: 16px;
  font-weight: 500;
}
.settings-overview-text11 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container30 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.settings-overview-container31 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact2-first-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container32 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact2-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container33 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-overview-settings-overview-support-contact2-email {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.settings-overview-container34 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.settings-overview-container35 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.settings-overview-container36 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-overview-text12 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.settings-overview-text13 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container37 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.settings-overview-container38 {
  gap: 10px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-overview-text14 {
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}
.settings-overview-text15 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-overview-container40 {
  padding-left: 20px;
  padding-right: 20px;
}
.settings-overview-icon {
  width: 18px;
  height: 18px;
}
.settings-overview-text16 {
  text-transform: none;
  text-decoration: none;
}
.settings-overview-container41 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.settings-overview-container41:hover {
  background-color: #462365;
}
.settings-overview-icon2 {
  width: 18px;
  height: 18px;
}
.settings-overview-text17 {
  text-transform: none;
}
