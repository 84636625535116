.login-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.login-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-traffls-bg-1);
}
.login-container2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-top-bar {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: row;
}
.login-container3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container4 {
  gap: 20px;
  width: 424px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-traffls-color-400);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-traffls-color-000);
}
.login-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.login-container6 {
  gap: 10px;
  display: flex;
  align-items: center;
}
.login-image {
  height: 30px;
  object-fit: cover;
}
.login-botton-bar {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.login-text1 {
  display: none;
  font-size: 14px;
}
