.check-box-container {
  cursor: pointer;
  position: relative;
}
.check-box-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-traffls-main-1);
}
.check-box-icon {
  width: 15px;
  height: 15px;
}




































































































































































