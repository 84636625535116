.channels-group-channels-groups {
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.channels-group-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.channels-group-container1 {
  gap: 20px;
  display: flex;
  align-items: end;
}
.channels-group-text {
  font-size: 18px;
}
.channels-group-navlink {
  display: contents;
}
.channels-group-container2 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
}
.channels-group-icon {
  width: 18px;
  height: 18px;
}
.channels-group-text1 {
  text-transform: none;
  text-decoration: none;
}
.channels-group-container3 {
  gap: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
}
.channels-group-container3.empty {
  border: 2px dashed #DFDFDF;
  height: 170px;
}
