.session-cookie-connect-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.session-cookie-connect-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.session-cookie-connect-content {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-connect-content1 {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-traffls-bg-1);
}
.session-cookie-connect-container01 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.session-cookie-connect-container02 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
  cursor: pointer;
}
.session-cookie-connect-icon {
  width: 18px;
  height: 18px;
}
.session-cookie-connect-text {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container03 {
  gap: 10px;
  fill: var(--dl-color-traffls-main-1);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #ffffff;
}
.session-cookie-connect-icon3 {
  width: 18px;
  height: 18px;
}
.session-cookie-connect-text01 {
  font-size: 14px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container04 {
  gap: 50px;
  width: 100%;
  display: flex;
  padding: 30px;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-traffls-white);
}
.session-cookie-connect-container05 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.session-cookie-connect-container06 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.session-cookie-connect-text02 {
  font-size: 16px;
}
.session-cookie-connect-text03 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container07 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.session-cookie-connect-container08 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.session-cookie-connect-session-cookie-overview-owner-name-first-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.session-cookie-connect-container09 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.session-cookie-connect-session-cookie-overview-owner-name-last-name {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.session-cookie-connect-container10 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.session-cookie-connect-session-cookie-overview-owner-name-email {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.session-cookie-connect-container11 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.session-cookie-connect-container12 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.session-cookie-connect-text04 {
  font-size: 16px;
}
.session-cookie-connect-text05 {
  width: 100%;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container13 {
  flex: 0 0 auto;
  width: 350px;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-traffls-bg-1);
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.session-cookie-connect-session-cookie-overview-details-cookie-value {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
}
.session-cookie-connect-container14 {
  gap: 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 50px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.session-cookie-connect-container15 {
  gap: 20px;
  width: 260px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.session-cookie-connect-text06 {
  font-size: 16px;
}
.session-cookie-connect-text07 {
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container16 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-connect-container17 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.session-cookie-connect-container18 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.session-cookie-connect-container19 {
  gap: 5px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-connect-text09 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container20 {
  flex: 0 0 auto;
  width: 306px;
  height: 115px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: var(--dl-color-traffls-bg-1);
}
.session-cookie-connect-container21 {
  gap: 5px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.session-cookie-connect-text11 {
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-family: "Be Vietnam Pro";
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.session-cookie-connect-container23 {
  padding-left: 20px;
  padding-right: 20px;
}
.session-cookie-connect-icon5 {
  width: 18px;
  height: 18px;
}
.session-cookie-connect-text12 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.session-cookie-connect-container24 {
  gap: 10px;
  fill: var(--dl-color-traffls-white);
  color: var(--dl-color-traffls-white);
  height: 40px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  text-decoration: none;
  background-color: #542f7a;
  cursor: pointer;
}
.session-cookie-connect-container24:hover {
  background-color: #462365;
}
.session-cookie-connect-icon7 {
  width: 18px;
  height: 18px;
}
.session-cookie-connect-text13 {
  font-size: 14px;
  font-family: "Be Vietnam Pro";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
